import React, { useState } from "react"
import "../../theme/type.scss"
import CustomFooter from "../../components/CustomFooter"
import GdprCookies from "../../components/GdprCookies"
import HeaderLayout from "../../components/layout/HeaderLayout"
import "../../theme/layout.scss"
import "../../theme/david.scss"
import { Grid, Card, CardContent, Typography } from "@material-ui/core"
import ModalSuscribe from "../../components/layout/ModalSuscribre"


const SemanalDirectorio = ({ data }) => {
  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }


  return (
    <>
      <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe} />
      <div className="wrap-content">
        <div className="header--section">
          <h2 className="title--section">DIRECTORIO</h2>
        </div>

        <Grid container justifyContent="center" spacing={2}>
          <Grid xs={12} md={4} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Rodolfo Navarrete
                </Typography>
                <p>
                  Chief Economist Officer
                </p>
                <Typography variant="body2">
                  rnavarre@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3646
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Marco Antonio Montañez
                </Typography>
                <p>
                  Director de Análisis y Estrategia
                </p>
                <p>
                  Aerolíneas, Aeropuertos, Bebidas, Financieras, Salud
                </p>
                <p>
                  AC, ASUR, BOLSA, CUERVO, FEMSA, GAP, GFNORTE, KOF, LAB,
                  OMA, VOLAR
                </p>
                <Typography variant="body2">
                  mmontane@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3706
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Luis Adrián Muñíz
                </Typography>
                <p>
                  Analista
                </p>
                <p>
                  Economía Nacional
                </p>
                <Typography variant="body2">
                  lmuniz@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3256
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3777
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Gerardo Cevallos, CFA
                </Typography>
                <p>
                  Analista Fundamental
                </p>
                <p>
                  Autopartes, Comunicaciones, Construcción,
                  Energía, Entretenimiento y Medios Minería,
                  Química y Petroquímica, Siderurgia

                </p>
                <p>ALFA, ALPEK, AMX, CEMEX, GMEXICO,
                  NEMAK, ORBIA, PENOLES, PINFRA, TLEVISA</p>
                <Typography variant="body2">
                  gcevallo@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3686
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Marcela Muñoz Moheno
                </Typography>
                <p>
                  Analista Fundamental

                </p>
                <p>
                  Alimentos, Consumo, Comercio,
                  Fibras.
                </p>
                <p>ALSEA, BIMBO, CHDRAUI, DAHNOS,
                  FUNO, GRUMA, <br /> KIMBER, LACOMER,
                  LIVEPOL, SORIANA, WALMEX</p>

                <Typography variant="body2">
                  mmunoz@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3156
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Alejandro Arellano Best
                </Typography>
                <p>
                  Analista
                </p>
                <p>
                  Economía Internacional

                </p>
                <Typography variant="body2">
                  aarellan@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3154
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Georgina Muñiz
                </Typography>
                <p>
                  Analista Técnico
                </p>
                <Typography variant="body2">
                  gmuniz@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3777
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Marco Antonio Castañeda
                </Typography>
                <p>
                  Analista Fundamental Jr
                </p>
                <Typography variant="body2">
                  mcastane@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3015
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4} xs={12} item>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  Ricardo Bravo
                </Typography>
                <p>
                  Analista Renta Fija
                </p>
                <Typography variant="body2">
                  rbravo@vector.com.mx
                </Typography>
                <Typography variant="body2">
                  01800-833-6800 / x3572
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>
  )
}

export default SemanalDirectorio
